import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import ApprovalStatusSummary from '@/reviews/components/ApprovalStatusSummary';

const propTypes = {
  onClick: PropTypes.func,
  approval_status: PropTypes.string,
};

const defaultProps = {
  onClick: undefined,
  approval_status: null,
};

const fragments = {
  postset: gql`
    fragment ReviewsButton on Postset {
      __typename
      id
      approval_status
      reviews(orderBy: [{ column: "created_at", order: DESC }]) {
        __typename
        id
        model_id
        model_type
        reviewer_id
        accepted_at
        declined_at
        reviewer {
          id
          email
        }
      }
      posts {
        __typename
        id
        deleted_at
      }
      referral_outreaches {
        __typename
        id
      }
    }
  `,
};

function ReviewsButton({ onClick, approval_status }) {
  return (
    <ApprovalStatusSummary
      id="approval-status-button"
      onClick={onClick}
      approval_status={approval_status}
    />
  );
}

ReviewsButton.propTypes = propTypes;
ReviewsButton.defaultProps = defaultProps;
ReviewsButton.fragments = fragments;

export default ReviewsButton;
